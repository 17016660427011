import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 6px 0px -2px rgba(0, 0, 0, 0.2);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :hover {
    transform: translateY(-5px);
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 600px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 20) {
      newMintAmount = 20;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen>
      {/* Story Time */}
      <s.Screen>
        <s.Container
          flex={1}
          ai={"center"}
          style={{
            padding: 24,
            backgroundColor: "var(--primary)",
            backgroundSize: "cover",
          }}
          image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg1.png" : null}
        >
          <StyledLogo alt={"logo"} src={"/config/images/logo.png"} />
          <h1 style={{ fontSize: 40, color: "#ee7aa1" }}>
            <b>by beezo</b>
          </h1>
          <s.SpacerLarge></s.SpacerLarge>
          <s.styledrow>
            <s.styledcolumn>
              <h1 style={{ fontSize: 40 }}>
                <b>Story Time</b>
              </h1>
              <s.SpacerMedium></s.SpacerMedium>
              <s.TextTitle
                style={{ textAlign: "center", color: "var(--accent-text)" }}
              >
                While quite extraordinary animals, common octopuses are all
                alike. They try to blend in to become invisible to predators and
                any exception from that norm is frowned upon. However, there are
                octopuses that do not care about any of this. They are vibrant,
                they love the bling and embrace being different. Show the bland
                octo society the middle finger by owning one of 10000 unique,
                randomly generated Octo-Misfits (OMS) and join a movement to
                rule the deep sea!
              </s.TextTitle>
              <s.SpacerMedium></s.SpacerMedium>
              <s.TextTitle
                style={{ textAlign: "center", color: "var(--accent-text)" }}
              >
                Octo-Misfits exhibit multiple of more than 130 traits with
                varying rarity. In case you are late to presale you can purchase
                your very own Octo-Misfit on OpenSea following the link below.
                Make sure to follow us on twitter to receive important updates.
              </s.TextTitle>
              <s.SpacerLarge></s.SpacerLarge>
              <s.styledrow>
                <s.styledcolumn
                  style={{ justifyContent: "center", alignItems: "center" }}
                >
                  <a href="https://opensea.io/collection/octomisfits">
                    <StyledButton
                      style={{
                        backgroundColor: "#3d9c7f",
                        width: 200,
                        padding: 5,
                      }}
                    >
                      <StyledLogo
                        style={{ width: "80%", height: "80%" }}
                        src={"/config/images/opensealogo.svg"}
                      />
                    </StyledButton>
                  </a>
                </s.styledcolumn>
                <s.styledcolumn>
                  <a href={"https://twitter.com/octomisfits"}>
                    <StyledButton
                      style={{
                        backgroundColor: "#3d9c7f",
                        width: 200,
                        padding: 5,
                      }}
                    >
                      <StyledLogo
                        style={{ width: "75%", height: "75%" }}
                        src={"/config/images/twitterlogo.svg"}
                      />
                    </StyledButton>
                  </a>
                </s.styledcolumn>
              </s.styledrow>
            </s.styledcolumn>
            <s.styledcolumn>
              <s.StyledPreview
                alt={"logo"}
                src={"/config/images/octo-king.png"}
              />
            </s.styledcolumn>
          </s.styledrow>
        </s.Container>
      </s.Screen>
      {/* Minting Rewards*/}
      <s.Screen>
        <s.Container
          flex={1}
          ai={"center"}
          style={{
            padding: 24,
            backgroundColor: "var(--primary)",
            backgroundSize: "cover",
          }}
          image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg2.png" : null}
        >
          <s.styledrow>
            <s.styledcolumn></s.styledcolumn>
            <s.styledcolumn>
              <h1 style={{ fontSize: 40 }}>
                <b>Minting Rewards</b>
              </h1>
            </s.styledcolumn>
          </s.styledrow>
          <s.styledrow>
            <s.styledcolumn>
              <s.Container style={{ height: "100%" }}>
                <s.OuterProg>
                  <s.InnerProg style={{ width: "25%" }}>
                    <p>2500 OMS minted</p>
                  </s.InnerProg>
                </s.OuterProg>
                <s.SpacerLarge></s.SpacerLarge>
                <s.OuterProg>
                  <s.InnerProg style={{ width: "50%" }}>
                    <p>5000 OMS minted</p>
                  </s.InnerProg>
                </s.OuterProg>
                <s.SpacerLarge></s.SpacerLarge>
                <s.OuterProg>
                  <s.InnerProg style={{ width: "75%" }}>
                    <p>7500 OMS minted</p>
                  </s.InnerProg>
                </s.OuterProg>
                <s.SpacerLarge></s.SpacerLarge>
                <s.OuterProg>
                  <s.InnerProg style={{ width: "100%" }}>
                    <p>10000 OMS minted</p>
                  </s.InnerProg>
                </s.OuterProg>
                <s.SpacerLarge></s.SpacerLarge>
              </s.Container>
            </s.styledcolumn>
            <s.styledcolumn>
              <s.TextTitle
                style={{ textAlign: "center", color: "var(--accent-text)" }}
              >
                Octo-Misfits are NFTs living on the Ethereum Blockchain. You can
                mint your own little octo using the minting dapp below which
                transfers a random Octo-Misfit to your wallet. Minting is free
                disregarding gas fees but a maximum of 20 Octos per Account
                applies to give everyone the chance to get a hand on these
                awesome creatures. It pays to be early though as with each
                minting goal five Octo-Misfits will be dropped to random token
                holders. This gives you the chance to participate in up to four
                reward drops if you are amongst the first movers!
              </s.TextTitle>
            </s.styledcolumn>
          </s.styledrow>
        </s.Container>
      </s.Screen>
      {/* Minting Dapp*/}
      <s.Screen>
        <s.Container
          flex={1}
          ai={"center"}
          style={{
            padding: 24,
            backgroundColor: "var(--primary)",
            backgroundSize: "cover",
          }}
          image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg3.png" : null}
        >
          <s.styledrow>
            <s.styledcolumn>
              <h1 style={{ fontSize: 40, color: "white" }}>
                <b>Mint Your Octo-Misfit Here</b>
              </h1>
            </s.styledcolumn>
            <s.styledcolumn></s.styledcolumn>
          </s.styledrow>
          <s.styledrow>
            <s.styledcolumn>
              <s.SpacerMedium></s.SpacerMedium>
              <s.TextTitle
                style={{ textAlign: "center", color: "var(--accent-text)" }}
              >
                Presale of Octo-Misfits has ended. To celebrate the great
                hatching you can now mint your very own Octo-Misfit for free.
                Connect with your web3 wallet and take part in the madness ;-).
              </s.TextTitle>
              <s.SpacerMedium></s.SpacerMedium>
              <s.Container flex={1} jc={"center"} ai={"center"}>
                <StyledImg
                  alt={"example"}
                  src={"/config/images/showcase.gif"}
                  style={{ transform: "scaleX(-1)" }}
                />
              </s.Container>
            </s.styledcolumn>
            <s.styledcolumn>
              <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
                <s.SpacerLarge />
                <s.Container
                  flex={2}
                  jc={"center"}
                  ai={"center"}
                  style={{
                    backgroundColor: "var(--accent)",
                    padding: 24,
                    borderRadius: 24,
                    border: "4px var(--secondary)",
                    boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
                  }}
                >
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 50,
                      fontWeight: "bold",
                      color: "var(--accent-text)",
                    }}
                  >
                    {data.totalSupply} / {CONFIG.MAX_SUPPLY}
                  </s.TextTitle>
                  <s.TextDescription
                    style={{
                      textAlign: "center",
                      color: "var(--primary-text)",
                    }}
                  >
                    <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                      {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
                    </StyledLink>
                  </s.TextDescription>
                  <s.SpacerSmall />
                  {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                    <>
                      <s.TextTitle
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        The sale has ended.
                      </s.TextTitle>
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        You can still find {CONFIG.NFT_NAME} on
                      </s.TextDescription>
                      <s.SpacerSmall />
                      <StyledLink
                        target={"_blank"}
                        href={CONFIG.MARKETPLACE_LINK}
                      >
                        {CONFIG.MARKETPLACE}
                      </StyledLink>
                    </>
                  ) : (
                    <>
                      <s.TextTitle
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        Mint your {CONFIG.SYMBOL} now for {CONFIG.DISPLAY_COST}{" "}
                        {CONFIG.NETWORK.SYMBOL}.
                      </s.TextTitle>
                      <s.SpacerXSmall />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        Excluding gas fees.
                      </s.TextDescription>
                      <s.SpacerSmall />
                      {blockchain.account === "" ||
                      blockchain.smartContract === null ? (
                        <s.Container ai={"center"} jc={"center"}>
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            Connect to the {CONFIG.NETWORK.NAME} network
                          </s.TextDescription>
                          <s.SpacerSmall />
                          <StyledButton
                            onClick={(e) => {
                              e.preventDefault();
                              dispatch(connect());
                              getData();
                            }}
                          >
                            CONNECT
                          </StyledButton>
                          {blockchain.errorMsg !== "" ? (
                            <>
                              <s.SpacerSmall />
                              <s.TextDescription
                                style={{
                                  textAlign: "center",
                                  color: "var(--accent-text)",
                                }}
                              >
                                {blockchain.errorMsg}
                              </s.TextDescription>
                            </>
                          ) : null}
                        </s.Container>
                      ) : (
                        <>
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            {feedback}
                          </s.TextDescription>
                          <s.SpacerMedium />
                          <s.Container ai={"center"} jc={"center"} fd={"row"}>
                            <StyledRoundButton
                              style={{ lineHeight: 0.4 }}
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                decrementMintAmount();
                              }}
                            >
                              -
                            </StyledRoundButton>
                            <s.SpacerMedium />
                            <s.TextDescription
                              style={{
                                textAlign: "center",
                                color: "var(--accent-text)",
                              }}
                            >
                              {mintAmount}
                            </s.TextDescription>
                            <s.SpacerMedium />
                            <StyledRoundButton
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                incrementMintAmount();
                              }}
                            >
                              +
                            </StyledRoundButton>
                          </s.Container>
                          <s.SpacerSmall />
                          <s.Container ai={"center"} jc={"center"} fd={"row"}>
                            <StyledButton
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                claimNFTs();
                                getData();
                              }}
                            >
                              {claimingNft ? "BUSY" : "BUY"}
                            </StyledButton>
                          </s.Container>
                        </>
                      )}
                    </>
                  )}
                  <s.SpacerMedium />
                </s.Container>
                <s.SpacerLarge />
              </ResponsiveWrapper>
              <s.SpacerSmall />
              <s.SpacerMedium />
              <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
                <s.TextDescription
                  style={{
                    textAlign: "center",
                    color: "var(--primary-text)",
                  }}
                >
                  Please make sure you are connected to the right network (
                  {CONFIG.NETWORK.NAME} Mainnet) and the correct address. Please
                  note: Once you make the purchase, you cannot undo this action.
                </s.TextDescription>
                <s.SpacerSmall />
                <s.TextDescription
                  style={{
                    textAlign: "center",
                    color: "var(--primary-text)",
                  }}
                >
                  We have set the gas limit to {CONFIG.GAS_LIMIT} for the
                  contract to successfully mint your NFT. We recommend that you
                  don't lower the gas limit.
                </s.TextDescription>
              </s.Container>
            </s.styledcolumn>
          </s.styledrow>
        </s.Container>
      </s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{
          padding: 24,
          color: "white",
          backgroundColor: "var(--primary)",
          backgroundSize: "cover",
        }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg1.png" : null}
      >
        <h1>Copyright Notice:</h1>
        <s.SpacerSmall></s.SpacerSmall>
        With purchase of your OMS Token you receive full right of ownership to
        the associated artwork and are free to do with your Octo as you please.
        This includes commercial uses. However, it is required that you treat
        your Octo with respect and leave them enough space to do their thing
        ;-).
      </s.Container>
    </s.Screen>
  );
}

export default App;
